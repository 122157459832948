// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './componets/Navbar';
import PrivateRoute from './componets/PrivateRoute';
import Explore from './pages/Explore';
import ForgotPassword from './pages/ForgotPassword' ;
import Offers from './pages/Offers';
import Category from './pages/Category';
import Profile from './pages/Profile';
import SingUp from './pages/SingUp';
import SignIn from './pages/SignIn';
import CreateListing from './pages/CreateListing';
import Listing from './pages/Listing';
import Contact from './pages/Contact';
import EditListing from './pages/EditListing';

// user: yakun.zhao@outlook.com
// 123456!!!

// // eslint-disable-next-line no-lone-blocks
// {/* 
// <Link /> would render a <a> tag to the DOM and is a link that the user would click to go to some other page in your app.
// <Navigate /> would be a component you can return that would redirect a user to a specific route within your app.
// useNavigate is much the same as <Navigate /> but you can call a function to navigate the user rather than return a component.
// useParams would give you access to the route parameters you defined when you created your routes.
// So for example if you had
// <Route path='/tasks/:taskId' element={<TaskPage />} />
// You could then access in TaskPage taskId like
// const { taskId } = useParams()
// useLocation will give you the current url of the page you are on in your app */}

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Explore />} />
          <Route path='/offers' element={<Offers />} />
          <Route path='/category/:categoryName' element={<Category />} />
          <Route path='/profile' element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
          </Route>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SingUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/create-listing' element={<CreateListing />} />
          <Route path='/edit-listing/:listingId' element={<EditListing />} />
          <Route path='/category/:categoryName/:listingId' element={<Listing />} />
          <Route path='/contact/:landlordId' element={<Contact />} />
        </Routes>
        {/* Navbar */}
        <Navbar />
      </Router>

      <ToastContainer />

    </>
  );
}

export default App;
