import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
// Outlet: render child routes or child elements.
import useAuthStatus from '../hooks/useAuthStatus'
import Spinner from '../componets/Spinner'

function PrivateRoute() {
    // const loggedIn = false
    const { loggedIn, checkingStatus } = useAuthStatus()
    // destructure from useAuthStatus, the two values that it returns, which is loggedIn and checkingStatus.

    // if checkingStatus is true
    if (checkingStatus) {
        return <Spinner />
        // <h3>Loading...</h3>
    }

    return loggedIn ? <Outlet /> : <Navigate to='/sign-in' />
    // basically just allows us to return child elements.
}

export default PrivateRoute
