import React from 'react'
import { useState, useEffect } from 'react'
//useParams need to be able to get the I.D. and then the category name from the URL.
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

// import { SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import  { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

import { getDoc, doc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { db } from '../firebase.config'
import Spinner from '../componets/Spinner'
import shareIcon from '../assets/svg/shareIcon.svg'
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

function Listing() {
    const [listing, setListing] = useState(null)
    const [loading, setLoading] = useState(true)
    const [shareLinkCopied, setShareLinkCopied] = useState(false)

    const navigate = useNavigate()
    const params = useParams()
    const auth = getAuth()

    useEffect(() => {
        const fetchListing = async () => {
            const docRef = doc(db, 'listings', params.listingId)
            const docSnap = await getDoc(docRef)

            if (docSnap.exists()) {
                // console.log(docSnap.data())
                setListing(docSnap.data())
                setLoading(false)
            }
        }
        fetchListing()
    }, [navigate, params.listingId])
    // console.log(params.listingId)

    if (loading) {
        return <Spinner />
    }

    // console.log(listing.useRef)
    // console.log(auth.currentUser?.uid)
    // console.log([listing.geolocation.lat, listing.geolocation.lng])

    return <main>
        {/* slider */}
        <Swiper modules={[Navigation, Pagination, Scrollbar, A11y]} slidesPerView={1} pagination={{ clickable: true }}>
            {listing.imageUrls.map((url, index) => (
                <SwiperSlide key={index}>
                    <div style={{
                        background: `url(${listing.imageUrls[index]}) center no-repeat`, backgroundSize: 'cover', minHeight: "20rem",
                    }} className='swiperSlideDiv'>

                    </div>
                </SwiperSlide>
            ))}
        </Swiper>

        {/*  share link */}
        <div className='shareIconDiv' onClick={() => {
            navigator.clipboard.writeText(window.location.href)
            setShareLinkCopied(true)
            setTimeout(() => {
                setShareLinkCopied(false)
            }, 2000)
        }}>
            <img src={shareIcon} alt='' />
        </div>

        {shareLinkCopied && <p className='linkCopied'>
            Link Copied!
        </p>}

        {/* Listing details */}
        <div className='listingDetails'>
            <p className='listingName'>
                {listing.name} - $
                {listing.offer ? listing.discountedPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : listing.regularPrice
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </p>

            <p className='listingLocation'>
                {listing.location}
            </p>

            <p className='listingType'>
                For {listing.type === 'rent' ? 'Rent' : 'Sale'}
            </p>

            {listing.offer && (
                <p className='discountPrice'>
                    ${listing.regularPrice - listing.discountedPrice} discount
                </p>
            )}

            <ul className='listingDetailsList'>
                <li>
                    {listing.bedrooms > 1 ? `${listing.bedrooms} Bedrooms` : `1 Bedroom`}
                </li>
                <li>
                    {listing.bathrooms > 1 ? `${listing.bathrooms} Bathrooms` : `1 Bathroom`}
                </li>
                <li>
                    {listing.parking && 'parking Spot'}
                </li>
                <li>
                    {listing.furnished && 'Furnished'}
                </li>
            </ul>

            <p className='listingLocationTitle'>
                Location
            </p>

            {/*  map */}
            <div className='leafletContainer'>
                <MapContainer style={{ height: '100%', width: '100%' }} center={[listing.geolocation.lat, listing.geolocation.lng]} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png'
                    />
                    <Marker position={[listing.geolocation.lat, listing.geolocation.lng]}>
                        <Popup>
                            {listing.location}
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>

            {/* ? so we don't get an error saying that's null */}
            {auth.currentUser?.uid !== listing.useRef && (
                <Link to={`/contact/${listing.userRef}?listingName=${listing.name}`} className='primaryButton'>
                    Contact Landlord
                </Link>
            )}
        </div>
    </main>
}

export default Listing
