import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Link, useNavigate, route diff?
import { toast } from 'react-toastify';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import OAuth from '../componets/OAuth';
import { ReactComponent as ArrowRightIcon } from '../assets/svg/keyboardArrowRightIcon.svg'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'

function SignIn() {
    const [showPassword, setShowPassword] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    const { email, password } = formData

    // {/* what is navigate?? */ }
    const navigate = useNavigate()

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            const auth = getAuth()

            const useCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            )

            if (useCredential.user) {
                navigate('/')
            }

        } catch (error) {
            // console.log(error)
            toast.error('Bad User Credential')
        }
    }

    return (
        <>
            <div className='pageContainer'>
                <header>
                    <p className='pageHeader'>
                        Welcome Back!
                    </p>
                </header>

                <form onSubmit={onSubmit}>
                    <input type='email' className='emailInput' placeholder='Email' id='email' value={email} onChange={onChange} />

                    <div className='passwordInputDiv'>
                        <input type={showPassword ? 'text' : 'password'} className='passwordInput' placeholder='Password' id='password' value={password} onChange={onChange} />

                        <img src={visibilityIcon} alt='show password' className='showPassword' onClick={() => setShowPassword((prevState) => !prevState)} />
                    </div>

                    <Link to='/forgot-password' className='forgotPasswordLink'>Forgot Password</Link>

                    {/* Sign In link?? */}
                    <div className='signInBar'>
                        <p className='signInText'>
                            Sign In
                        </p>
                        <button className='signInButton'>
                            <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
                        </button>
                    </div>
                </form>

                {/* Google OAuth */}
                <OAuth/>

                <Link to='/sign-up' className='registerLink'>
                    Sign Up instead
                </Link>
            </div>
        </>
    )
}

export default SignIn
