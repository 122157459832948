import React from 'react'
import { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom'
import { collection, getDocs, query, where, orderBy, limit, startAfter } from 'firebase/firestore'
// startAfter: pagination 分页
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import Spinner from '../componets/Spinner'
import ListItem from '../componets/ListItem'


// Category: fetch listing from firebase
function Offers() {
  const [listings, setListings] = useState(null)
  const [loading, setLoading] = useState(true)
  // true by default, once fetch listings, will set it to false.
  const [lastFetchedListing, setLastFetchListing] = useState(null)

  //  const params = useParams()

  useEffect(() => {
    const fetchListings = async () => {
      try {
        // Get reference
        // fetch a reference, this is a reference to the collection, not the document like we were doing before. 
        // use collection take in DB and it's going to take in listings collection
        const listingsRef = collection(db, 'listings')

        // Create a query
        // use query
        // categoryName ref to app.js route
        const q = query(listingsRef, where('offer', '==', true), orderBy('timestamp', 'desc'), limit(10))

        // Excute query
        const querySnap = await getDocs(q)

        const lastVisible = querySnap.docs[querySnap.docs.length - 1]
        setLastFetchListing(lastVisible)

        const listings = []

        querySnap.forEach((doc) => {
          console.log(doc.data())
          return listings.push({
            id: doc.id,
            data: doc.data()
          })
        })

        setListings(listings)
        setLoading(false)
      } catch (error) {
        toast.error('Could not fetch listings 000')
      }
    }
    fetchListings()

  }, [])

  // Pagination / Load more
  const onFetchMoreListings = async () => {
    try {
      // Get reference
      // fetch a reference, this is a reference to the collection, not the document like we were doing before. 
      // use collection take in DB and it's going to take in listings collection
      const listingsRef = collection(db, 'listings')

      // Create a query
      // use query
      // categoryName ref to app.js route
      const q = query(listingsRef, where('offer', '==', true), orderBy('timestamp', 'desc'), startAfter(lastFetchedListing), limit(10))

      // Excute query
      const querySnap = await getDocs(q)

      const lastVisible = querySnap.docs[querySnap.docs.length - 1]
      setLastFetchListing(lastVisible)

      const listings = []

      querySnap.forEach((doc) => {
        console.log(doc.data())
        return listings.push({
          id: doc.id,
          data: doc.data()
        })
      })

      setListings((prevState) => [...prevState, ...listings])
      setLoading(false)
    } catch (error) {
      toast.error('Could not fetch listings 444')
    }
  }

  return (
    <div className='category'>
      <header>
        <p className='pageHeader'>
          {/* {params.categoryName === 'rent' ? 'Places for rent' : 'places for sell'} */}
          Offers
        </p>
      </header>

      {loading ? (<Spinner />) : listings && listings.length > 0 ? (
        <>
          <main>
            {/* function listing is an object with an ID and a data */}
            <ul className='categoryListings'>
              {listings.map((listing) => (
                <ListItem listing={listing.data} id={listing.id} key={listing.id} />

              ))}
            </ul>
          </main>

          <br />
          <br />
          {
            lastFetchedListing && (
              <p className='loadMore' onClick={onFetchMoreListings}>
                Load More
              </p>
            )
          }

        </>) : (<p>There is no current offer</p>)}

    </div>
  )
}

export default Offers
