// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore} from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7O53aesmr6InkH4ZWbPIk0f3DEd6cwzQ",
  authDomain: "house-marketplace-app-67e3a.firebaseapp.com",
  projectId: "house-marketplace-app-67e3a",
  storageBucket: "house-marketplace-app-67e3a.appspot.com",
  messagingSenderId: "1079723428595",
  appId: "1:1079723428595:web:b855e9aeda1cc27bf50586"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)