import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import googleIcon from '../assets/svg/googleIcon.svg'

function OAuth() {
    const navigate = useNavigate()
    const location = useLocation()

    const onGoogleClick = async () => {
        try {
            const auth = getAuth()
            const provider = new GoogleAuthProvider()
            // signInWithPopup is a function take in auth and provider
            const result = await signInWithPopup(auth, provider)
            // result is a object
            const user = result.user

            // check if there is user, for docRef, you also can call it userRef
            // doc takes in db from config, collection 'users'
            // getting the user from the Google sign in and then we're passing that the user ID in here to see if we have a reference to that document.
            const docRef = doc(db, 'users', user.uid)
            const docSnap = await getDoc(docRef)

            //if user does not exist, create user
            // meathon called exists
            if (!docSnap.exists()) {
                await setDoc(doc(db, 'users', user.uid), {
                    name: user.displayName,
                    email: user.email,
                    timestamp: serverTimestamp()
                })

                //and set Doc has two parameters, which is this doc(db, 'user', user.id). And then the next one is the data that we want to add to the database:
                // {
                //     name: user.displayName,
                //     email: user.email,
                //     timestamp: serverTimestamp()
                // }
            }
            navigate('/')

        } catch (error) {
            console.log("This is the error we are looking for", error);
            toast.error('Could not authorize with Google')
        }
    }

    return (
        <div className='socialLogin'>
            <p>
                Sign {location.pathname === '/sign-up' ? 'up' : 'in'}with
            </p>

            <button className='socialIconDiv' onClick={onGoogleClick}>
                <img className='socialIconImg' src={googleIcon} alt='google' />
            </button>
        </div>
    )
}

export default OAuth
