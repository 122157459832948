import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import OAuth from '../componets/OAuth';
import { setDoc, doc, serverTimestamp} from 'firebase/firestore'
import {db} from '../firebase.config'
import { toast } from 'react-toastify';
import { ReactComponent as ArrowRightIcon } from '../assets/svg/keyboardArrowRightIcon.svg'
import visibilityIcon from '../assets/svg/visibilityIcon.svg'

function SignUp() {
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  })
  const { name, email, password } = formData

  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const auth = getAuth()

      const useCredential = await createUserWithEmailAndPassword (
        auth,
        email,
        password
      )

      const user = useCredential.user

      updateProfile(auth.currentUser, {
        displayName: name,

      })

      //______for set doc__________________
      const formDataCopy = {...formData}
      delete formDataCopy.password
      formDataCopy.timestamp = serverTimestamp()
      // add timestamp in object formDataCopy
      await setDoc (doc(db, 'users', user.uid), formDataCopy)
      // name of the collection 'users'
      // user.uid add user id in setDoc
      //_______for set doc_________________

      navigate('/')

    } catch (error) {
      // console.log(error)
      toast.error('Something went wrong with registration')
    }
  }

  return (
    <>
      <div className='pageContainer'>
        <header>
          <p className='pageHeader'>
            Welcome Back!
          </p>
        </header>

        <form onSubmit={onSubmit}>
          <input type='text' className='nameInput' placeholder='Name' id='name' value={name} onChange={onChange} />
          <input type='email' className='emailInput' placeholder='Email' id='email' value={email} onChange={onChange} />

          <div className='passwordInputDiv'>
            <input type={showPassword ? 'text' : 'password'} className='passwordInput' placeholder='Password' id='password' value={password} onChange={onChange} />

            <img src={visibilityIcon} alt='show password' className='showPassword' onClick={() => setShowPassword((prevState) => !prevState)} />
          </div>

          <Link to='/forgot-password' className='forgotPasswordLink'>Forgot Password</Link>

          <div className='signUpBar'>
            <p className='signUpText'>
              Sign Up
            </p>
            <button className='signUpButton'>
              <ArrowRightIcon fill='#ffffff' width='34px' height='34px' />
            </button>
          </div>
        </form>

        {/* Google OAuth */}
        <OAuth />

        <Link to='/sign-in' className='registerLink'>
          Sign In instead
        </Link>
      </div>
    </>
  )
}

export default SignUp
