// import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
// method called onAuthStateChanged, anytime the state changes, if we go from logged in to not logged in, this will fire off.


export const useAuthStatus = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [checkingStatus, setCheckingStatus] = useState(true)
    const isMounted = useRef(true)

    useEffect(() => {
        if (isMounted) {
            const auth = getAuth()
            // create a function called user, gives us back user object.
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setLoggedIn(true)
                }
                setCheckingStatus(false)
                // We only want to render if checking status is false.
            })
        }
        return () => {
            isMounted.current = false
        }
    }, [isMounted])

    return { loggedIn, checkingStatus }
}

export default useAuthStatus

// protected routes in v6
// https://stackoverflow.com/questions/65505665/protected-route-with-firebase

// fic memory warning
// https://stackoverflow.com/questions/59780268/cleanup-memory-leaks-on-an-unmounted-component-in-react-hooks