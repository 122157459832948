import React, { useState, useEffect } from 'react'
// import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAuth, updateProfile } from 'firebase/auth'
import { updateDoc, doc, collection, getDocs, query, where, orderBy, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ListItem from '../componets/ListItem'
import arrowRight from '../assets/svg/keyboardArrowRightIcon.svg'
import homeIcon from '../assets/svg/homeIcon.svg'


function Profile() {
  const auth = getAuth()
  // const [user, setUser] = useState(null)

  const [loading, setLoading] = useState(true)
  const [listings, setListings] = useState(null)

  const [changeDetails, setChangeDetails] = useState(false)

  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  })
  const { name, email } = formData
  // destruction and take the name and the email out from the form data

  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserListings = async () => {
      const listingsRef = collection(db, 'listings',)
      // only get listing suserRef value = logged in user.
      const q = query(listingsRef, where('userRef', '==', auth.currentUser.uid), orderBy('timestamp', 'desc'))

      const querySnap = await getDocs(q)

      let listings = []

      querySnap.forEach((doc) => {
        return listings.push({
          id: doc.id,
          data: doc.data()
        })
      })

      setListings(listings)
      setLoading(false)
    }

    fetchUserListings()

  }, [auth.currentUser.uid])

  // console.log([auth.currentUser.uid])
  // console.log(doc.id)

  const onLogout = () => {
    auth.signOut()
    navigate('/')
  }

  // update profile 
  const onSubmit = async () => {
    // console.log(123)
    try {
      if (auth.currentUser.displayName !== name) {
        // update display name in firebase?
        // updateProfile is a function from input
        // takes in auth.currentUser, update displayName:name
        await updateProfile(auth.currentUser, {
          displayName: name
        })

        // update in firebase
        // doc take in db fron congif, collctions: 'users', id: .auth.currentUser.uid
        const userRef = doc(db, 'users', auth.currentUser.uid)
        // updateDoc take in userRef, then update name
        await updateDoc(userRef, {
          //name: name
          name
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('Could not update profile detils')
    }
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  const onEdit = (listingId) => navigate(`/edit-listing/${listingId}`)

  const onDelete = async (listingId) => {
    if (window.confirm('Are you should you want to delete?')) {
      await deleteDoc(doc(db, 'listings', listingId))

      const updatedListings = listings.filter((listing) => listing.id !== listingId)

      setListings(updatedListings)
      toast.success('Successfully deleted listing')
    }
  }

  //loading && listings?.length

  // console.log(loading)
  // console.log(listings)

  // useEffect(() => {
  //   // console.log(auth.currentUser)
  //   setUser(auth.currentUser)
  // }, [])
  // return user ? <h1>{user.displayName} </h1> : 'Not Logged In'
  return <div className='profile'>
    <header className='profileHeader'>
      <p className='pageHeader'>
        My Profile
      </p>
      <button type='button' className='logOut' onClick={onLogout} >
        Logout
      </button>
    </header>

    <main>
      <div className='profileDetailsHeader'>
        <p className='profileDetailsText'>
          Personal Details
        </p>
        <p className='changePersonDetails' onClick={() => {
          changeDetails && onSubmit()
          setChangeDetails((prevState) => !prevState)
        }}>
          {changeDetails ? 'done' : 'change'}
        </p>
      </div>

      <div className='profileCard'>
        <form>
          <input type='text' id='name' className={!changeDetails ? 'profileName' : 'profileNameActive'} disable={!changeDetails} value={name} onChange={onChange} />
          <input type='text' id='email' className={!changeDetails ? 'profileEmail' : 'profileEmailActive'} disable={!changeDetails} value={email} onChange={onChange} />
        </form>

      </div>
      <Link to='/create-listing' className='createListing'>
        <img src={homeIcon} alt='home' />
        <p>
          Sell or rent your home
        </p>
        <img src={arrowRight} alt='arrow right' />
      </Link>

      {!loading && listings?.length > 0 && (
        <>
          <p className='listingText'>
            Your Listings
          </p>
          <ul className='listingsList'>
            {listings.map((listing) => (
              <ListItem key={listing.id} listing={listing.data} id={listing.id} onDelete={() => onDelete(listing.id)} onEdit={() => onEdit(listing.id)}>
              </ListItem>
            ))}
          </ul>
        </>
      )}
    </main>
  </div>
}

export default Profile
