/* eslint-disable default-case */
import React from 'react'
import { useState, useEffect, useRef } from 'react'
// use useRef avoiding unmounted memory leak warning
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase.config';
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '../componets/Spinner'

function EditListing() {
    // add la and long manually
    // eslint-disable-next-line 
    const [geolocationEnabled, setGeolocationEnabled] = useState(true)
    // use Google Geocoding to get the latitude and longitude.
    const [loading, setLoading] = useState(false)
    const [listing, setListing] = useState(false)
    const [formData, setFormData] = useState({
        type: 'rent',
        name: '',
        bedrooms: 1,
        bathrooms: 1,
        parking: false,
        furnished: false,
        address: '',
        offer: false,
        regularPrice: 0,
        discountedPrice: 0,
        images: {},
        latitude: 0,
        longitude: 0
    })
    // destructor formData, then do not need formData.type, can just use {type}
    const { type, name, bedrooms, bathrooms, parking, furnished, address, offer, regularPrice, discountedPrice, images, latitude, longitude } = formData

    const auth = getAuth()
    const navigate = useNavigate()
    const params = useParams()
    const isMounted = useRef(true)

    // redirect if listing is not user's
    useEffect(() => {
        if (listing && listing.userRef !== auth.currentUser.uid) {
            toast.error("you cn not edit this listing")
            navigate('/')
        }
    })

    // fetch listing to edit
    useEffect(() => {
        setLoading(true)
        const fetchListing = async () => {
            const docRef = doc(db, 'listings', params.listingId)
            const docSnap = await getDoc(docRef)

            if (docSnap.exists()) {
                setListing(docSnap.data())
                setFormData({ ...docSnap.data(), address: docSnap.data().location })
                setLoading(false)
            } else {
                navigate('/')
                toast.error('Listing does not exist')
            }
        }

        fetchListing()
    }, [params.listingId, navigate])

    // set useRef to login user
    useEffect(() => {
        if (isMounted) {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setFormData({ ...formData, userRef: user.uid })
                } else {
                    navigate('/sign-in')
                }
            })

        }
        return () => {
            isMounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    const onSubmit = async (e) => {
        e.preventDefault()
        // console.log(formData)

        setLoading(true)
        //_________________________
        if (discountedPrice >= regularPrice) {
            setLoading(false)
            toast.error('Discounted priceneeds to be less than regular price')

            return
        }
        //_________________________
        if (images.length > 6) {
            setLoading(false)
            toast.error('Max 6 images')

            return
        }

        //_________________________

        let geolocation = {

        }
        let location

        if (geolocationEnabled) {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
            );
            const data = await response.json();
            // console.log(data)

            // ! data.results[0]? why ?
            // ! why return? 
            geolocation.lat = data.results[0]?.geometry.location.lat ?? 0
            geolocation.lng = data.results[0]?.geometry.location.lng ?? 0

            location = data.status === 'ZERO_RESULTS' ? undefined : data.results[0]?.formatted_address

            // console.log(location)

            if (location === undefined || location.includes('undefined')) {
                setLoading(false)
                toast.error('Please enter a correct address')

                return
            }

        } else {
            geolocation.lat = latitude
            geolocation.lng = longitude
            // location = address
        }

        // store images in firebase
        // take in image
        const storeImage = async (image) => {
            // create a promise takes in a function with a resolve and reject
            return new Promise((resolve, reject) => {
                // init storage
                const storage = getStorage()
                const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
                //pass in storage. pass in the path to where we're putting this with - 'images/', concatenate on file name.
                const storageRef = ref(storage, 'images/' + fileName)

                const uploadTask = uploadBytesResumable(storageRef, image);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                            default:
                                break;
                        }
                    },
                    function (error) {
                        // Handle unsuccessful uploads
                        reject(error);
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // console.log('File available at', downloadURL);
                            resolve(downloadURL)
                        });
                    }
                );
            })
        }
        // https://firebase.google.com/docs/storage/web/upload-files?authuser=0

        // !Promise.all will resolve multiple promises.
        const imageUrls = await Promise.all(
            [...images].map((image) => storeImage((image)).catch(() => {
                setLoading(false)
                toast.error('Image not uploaded')
                return
            }))
        )

        // console.log(imageUrls)

        const formDataCopy = {
            ...formData,
            imageUrls,
            geolocation,
            timestamp: serverTimestamp()
        }

        formDataCopy.location = address
        // clean up
        // only want imageUrls
        delete formDataCopy.images
        // alreadly have format address
        delete formDataCopy.address
        // want to add location variable to formDataCopy object.
        // location && (formDataCopy.location = location)
        // if no offer delete discounted price from object
        !formDataCopy.offer && delete formDataCopy.discountedPrice
        //pass in collection and takes in db from Firebase config, name of the collection is listings
        // pass in another argument, the actual data called formDataCopy
        // const docRef = await addDoc(collection(db, 'listings'), formDataCopy)

        // update listing
        const docRef = doc(db, 'listings', params.listingId)
        await updateDoc(docRef, formDataCopy)

        setLoading(false)

        toast.success('Listings saved!')
        // navigate to the the listing that we just created, ${formDataCopy.type} is rent pr sale
        navigate(`/category/${formDataCopy.type}/${docRef.id}`)
    }


    //have to check to see if it's a file upload or not
    // ! why boolean???
    const onMutate = e => {
        let boolean = null

        if (e.target.value === 'true') {
            boolean = true
        }

        if (e.target.value === 'false') {
            boolean = false
        }

        // files
        if (e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                images: e.target.files
            }))
        }

        // text/boolean/number
        if (!e.target.files) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.id]: boolean ?? e.target.value,
            }))
        }
    }

    if (loading) {
        return <Spinner />
    }


    return <div className='profile'>
        <header>
            <p className='pageHeader'>
                Edit Listing
            </p>
        </header>

        <main>
            <form onSubmit={onSubmit}>
                <label className='formLabel'>
                    Sell / Rent
                </label>
                <div className='formButtons'>
                    <button type='button' className={type === 'sale' ? 'formButtonActive' : 'formButton'} id='type' value='sale' onClick={onMutate}>
                        Sell
                    </button>
                    {/* when we set our state, onChange or onClicks, it's going to look at the ID for  the key. In this case, it's type. */}
                    <button type='button' className={type === 'rent' ? 'formButtonActive' : 'formButton'} id='type' value='rent' onClick={onMutate}>
                        Rent
                    </button>
                </div>

                <label className='formLabel'>Name</label>
                <input
                    className='formInputName'
                    type='text'
                    id='name'
                    value={name}
                    onChange={onMutate}
                    maxLength='32'
                    minLength='10'
                    required
                />

                <div className='formRooms flex'>
                    <div>
                        <label className='formLabel'>Bedrooms</label>
                        <input
                            className='formInputSmall'
                            type='number'
                            id='bedrooms'
                            value={bedrooms}
                            onChange={onMutate}
                            min='1'
                            max='50'
                            required
                        />
                    </div>

                    <div>
                        <label className='formLabel'>Bathrooms</label>
                        <input
                            className='formInputSmall'
                            type='number'
                            id='bathrooms'
                            value={bathrooms}
                            onChange={onMutate}
                            min='1'
                            max='50'
                            required
                        />
                    </div>
                </div>

                <label className='formLabel'>Parking spot</label>
                <div className='formButtons'>
                    <button
                        className={parking ? 'formButtonActive' : 'formButton'}
                        type='button'
                        id='parking'
                        value={true}
                        onClick={onMutate}
                        min='1'
                        max='50'
                    >
                        Yes
                    </button>

                    <button
                        className={
                            !parking && parking !== null ? 'formButtonActive' : 'formButton'
                        }
                        type='button'
                        id='parking'
                        value={false}
                        onClick={onMutate}
                    >
                        No
                    </button>
                </div>

                <label className='formLabel'>Furnished</label>
                <div className='formButtons'>
                    <button
                        className={furnished ? 'formButtonActive' : 'formButton'}
                        type='button'
                        id='furnished'
                        value={true}
                        onClick={onMutate}
                    >
                        Yes
                    </button>

                    <button
                        className={
                            !furnished && furnished !== null
                                ? 'formButtonActive'
                                : 'formButton'
                        }
                        type='button'
                        id='furnished'
                        value={false}
                        onClick={onMutate}
                    >
                        No
                    </button>
                </div>

                <label className='formLabel'>Address</label>
                <textarea
                    className='formInputAddress'
                    type='text'
                    id='address'
                    value={address}
                    onChange={onMutate}
                    required
                />

                {!geolocationEnabled && (
                    <div className='formLatLng flex'>
                        <div>
                            <label className='formLabel'>Latitude</label>
                            <input
                                className='formInputSmall'
                                type='number'
                                id='latitude'
                                value={latitude}
                                onChange={onMutate}
                                required
                            />
                        </div>

                        <div>
                            <label className='formLabel'>Longitude</label>
                            <input
                                className='formInputSmall'
                                type='number'
                                id='longitude'
                                value={longitude}
                                onChange={onMutate}
                                required
                            />
                        </div>
                    </div>
                )}

                <label className='formLabel'>Offer</label>
                <div className='formButtons'>
                    <button
                        className={offer ? 'formButtonActive' : 'formButton'}
                        type='button'
                        id='offer'
                        value={true}
                        onClick={onMutate}
                    >
                        Yes
                    </button>

                    <button
                        className={
                            !offer && offer !== null ? 'formButtonActive' : 'formButton'
                        }
                        type='button'
                        id='offer'
                        value={false}
                        onClick={onMutate}
                    >
                        No
                    </button>
                </div>

                <label className='formLabel'>Regular Price</label>
                <div className='formPriceDiv'>
                    <input
                        className='formInputSmall'
                        type='number'
                        id='regularPrice'
                        value={regularPrice}
                        onChange={onMutate}
                        min='50'
                        max='750000000'
                        required
                    />

                    {type === 'rent' && <p className='formPriceText'>$ / Month</p>}
                </div>

                {offer && (
                    <>
                        <label className='formLabel'>Discounted Price</label>

                        <input
                            className='formInputSmall'
                            type='number'
                            id='discountedPrice'
                            value={discountedPrice}
                            onChange={onMutate}
                            min='50'
                            max='750000000'
                            required={offer}
                        />
                    </>
                )}

                <label className='formLabel'>Images</label>
                <p className='imagesInfo'>
                    The first image will be the cover (max 6).
                </p>

                <input
                    className='formInputFile'
                    type='file'
                    id='images'
                    onChange={onMutate}
                    max='6'
                    accept='.jpg,.png,.jpeg'
                    multiple
                    required
                />

                <button type='submit' className='primaryButton createListingButton'>
                    Edit Listing
                </button>

            </form>
        </main>
    </div>

}

export default EditListing
